<template>
    <v-data-table
        :headers="headers"
        :items="tours"
        :search="search"
        
        class="elevation-1"
    >
        <template v-slot:top>
            <v-toolbar flat class="mb-5">
                <v-toolbar-title class="font-weight-bold text-h5"
                    >ALL TOURS</v-toolbar-title
                >

                <v-spacer></v-spacer>

                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    clearable
                    label="Search"
                    single-line
                    hide-details
                    class="mr-10"
                ></v-text-field>
                <v-dialog v-model="dialog" persistent max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            outlined
                            color="dark"
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            New Tour
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span
                                class="text-h5 font-weight-bold text-uppercase primary--text"
                                >{{ formTitle }}</span
                            >
                        </v-card-title>

                        <!-- DIALOG add || edit-->
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col class="d-flex" cols="12" sm="12">
                                        <v-select
                                            :items="categories"
                                            item-text="name"
                                            item-value="id"
                                            label="Categories"
                                            v-model="editedTour.category_id"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                            v-model="editedTour.name"
                                            label="Tour name"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea
                                            name="desc"
                                            label="Description"
                                            v-model="editedTour.desc"
                                        ></v-textarea>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field
                                            v-model="editedTour.duration"
                                            label="Duration (day)"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field
                                            v-model="editedTour.price"
                                            label="Price ($)"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                                Cancel
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="handleFormSubmit"
                            >
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- DIALOG DELETE-->
                <dialog-delete @deleteConfirmed="deleteItemConfirm" />
            </v-toolbar>

            <v-toolbar flat class="mb-15">
                <v-row>
                    <v-col lg="6"></v-col>
                    <v-col>
                        <v-select
                            label="Category"
                            v-model="filter.category"
                            @change="handleFilter"
                            solo
                            dense
                            :items="categories"
                            :clearable="true"
                            item-text="name"
                            item-value="name"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                            label="Duration"
                            solo
                            prepend-icon="mdi-clock-outline"
                            dense
                            v-model="filter.duration"
                            @change="handleFilter"
                            :items="durations"
                            :clearable="true"
                            item-text="t"
                            item-value="id"
                        ></v-select>
                    </v-col>

                    <v-col>
                        <v-select
                            label="Price"
                            solo
                            prepend-icon="mdi-currency-eur"
                            dense
                            v-model="filter.price"
                            @change="handleFilter"
                            :items="prices"
                            :clearable="true"
                            item-text="t"
                            item-value="id"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-toolbar>
        </template>
        <template v-slot:item.price="{ item }">
            <v-chip :color="getColor(item.price)" dark small>
                {{ item.price }}
            </v-chip>
        </template>

        <!-- ACTIONS -->
        <template v-slot:item.actions="{ item }">
            <v-icon small @click="$router.push({name: 'tour-show', params: {id: item.id}})" class="mr-2">
                mdi-eye
            </v-icon>
            <v-icon small class="mr-2" @click="initTourEditDialog(item)">
                mdi-pencil
            </v-icon>
            <v-icon small @click="openDeleteDialog(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="fetchAllTours"> Reset </v-btn>
        </template>
    </v-data-table>
</template>

<script>
import {
    tourFilter,
    tourEdit,
    tourAdd,
    tourDelete,
} from "@/services/tourService";
import { categoryAll } from "@/services/categoryService";
import DialogDelete from "@/components/Dialogs/DialogDelete.vue";
import { mapActions } from "vuex";

export default {
    components: { DialogDelete },
    data: () => ({
        search: "",
        dialog: false,
        dialogDelete: false,
        headers: [
            {
                text: "ID",
                align: "start",
                sortable: false,
                value: "id",
            },
            
            { text: "Name", value: "name" },
            { text: "Description", value: "desc", sortable: false },
            { text: "Duration (Day)", value: "duration" },
            { text: "Price ($)", value: "price" },
            { text: "Category", value: "category.name" },
            { text: "Itineraries (Nbr)", value: "itineraries.length" },
            { text: "Actions", value: "actions", sortable: false },
        ],
        editedIndex: -1,
        defaultTour: {
            category: "",
            name: "",
            desc: "",
            duration: "",
            price: "",
        },
        editedTour: {
            category: "",
            name: "",
            desc: "",
            duration: "",
            price: "",
        },
        categories: [],
        tours: [],
        filter: {
            category: null,
            duration: null,
            price: null,
        },
        prices: [
            { id: 1500, t: "less than 1500" },
            { id: 3000, t: "from 1500 to 3500" },
            { id: 4000, t: "up to 3500" },
        ],
        durations: [
            { id: 7, t: "less than 7" },
            { id: 10, t: "from 7 to 14 days" },
            { id: 14, t: "up to 14 days" },
        ],
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "Add New Tour" : "Edit Tour";
        },
    },

    // watch: {
    //     dialog(val) {
    //         console.log(val);
    //         val || this.close();
    //     },
    //     dialogDelete(val) {
    //         val || this.closeDelete();
    //     },
    // },

    mounted() {
        this.fetchAllTours();
        categoryAll().then((res) => (this.categories = res.data.categories));
        
    },

    methods: {
        ...mapActions({
            openDialog: "modals/openDialog",
            closeDialog: "modals/closeDialog",
        }),
        async fetchAllTours() {
            const res = await tourFilter()            
            this.tours = res.data.trips;            
        },

        handleFormSubmit() {
            this.editedIndex === -1
                ? this.addNewTour(this.editedTour)
                : this.updateTour(this.editedTour.id, this.editedTour);
        },
        addNewTour(tour) {
            tourAdd(tour).then(() => {
                this.dialog = false;
                this.fetchAllTours();
            });
        },
        updateTour(tourId, tour) {
            tourEdit(tourId, tour).then(() => {
                this.dialog = false;
                this.fetchAllTours();
            });
        },

        initTourEditDialog(tour) {
            this.editedIndex = this.tours.indexOf(tour);
            this.editedTour = Object.assign({}, tour);
            this.dialog = true;
        },
        openDeleteDialog(data) {
            const trip = { id: data.id, name: data.name, item: "Tour" };
            this.openDialog(trip);
        },
        deleteItemConfirm() {
            const deletetedTour = this.tours.splice(this.editedIndex, 1);
            tourDelete(deletetedTour[0].id);
            this.closeDialog();
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedTour = Object.assign({}, this.defaultTour);
                this.editedIndex = -1;
            });
        },
        handleFilter() {
            const selectedFilter = Object.assign({}, this.filter);
            console.log(selectedFilter);
            tourFilter(selectedFilter).then((res) => {
                this.tours = res.data.trips;
            });
        },
        getColor(price) {
            if (price < 1500) return "green";
            else if (price < 3500) return "orange";
            else return "red";
        },
    },
};
</script>
