import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;
export const bus = new Vue();

new Vue({
    router,
    store,
    vuetify,
    beforeCreate() {
        this.$store.commit("auth/INIT_AUTH");
        if (!this.$store.state.auth.user)
            this.$router.push({ path: "/" }).catch(() => {});
    },
    render: (h) => h(App),
}).$mount("#app");
