<template>
    <v-dialog :value="true" persistent max-width="450">
        <v-form @submit.prevent="updateCategory">
            <v-card>
                <v-card-title
                    class="text-h5 light-blue--text font-weight-bold text-uppercase"
                >
                    Edit Category
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="item.name"
                        @keyup.enter="updateTask"
                        color="light-blue"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="$emit('closeDialog')" color="grey" text>
                        Cancel
                    </v-btn>
                    <v-btn type="submit" color="dark" text> Save </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { categoryEdit } from "@/services/categoryService";

export default {
    props: ["category"],
    data: () => ({
        item: "",
    }),
    mounted() {
        this.item = this.category;
    },
    computed: {},
    methods: {
        updateCategory() {
            categoryEdit(this.item.id, this.item).then((res) => {
                this.$emit("closeDialog");
            });
        },
    },
};
</script>
