import { api } from "./index";

export const categoryAll = async () => {
    return api.get("category/all");
};

export const categoryAdd = async (category) => {
    return api.post("category/add", category);
};

export const categoryShow = async (categoryId) => {
    return api.get(`/category/show/${categoryId}`);
};

export const categoryEdit = async (categoryId, data) => {
    return api.put(`/category/update/${categoryId}`, data);
};

export const categoryDelete = async (categoryId) => {
    return api.delete(`/category/delete/${categoryId}`);
};
