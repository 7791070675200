import { api } from "./index";

export const itineraryAll = async () => {
    return api.get("itinerary/all");
};

export const itineraryAdd = async (itinerary) => {
    return api.post("itinerary/add", itinerary);
};

export const itineraryShow = async (itineraryId) => {
    return api.get(`/itinerary/${itineraryId}`);
};

export const itineraryEdit = async (itineraryId, data) => {
    return api.put(`/itinerary/update/${itineraryId}`, data);
};

export const itineraryDelete = async (itineraryId) => {
    return api.delete(`/itinerary/delete/${itineraryId}`);
};