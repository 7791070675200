import Vue from "vue";
import VueRouter from "vue-router";
import SignIn from "../views/SignIn.vue";
import Wrapper from "../views/Wrapper.vue";
import Categories from "../views/Categories.vue";
import Tours from "../views/Tours.vue";
import Dashboard from "../components/Dashboard";
import HelloWorld from "../components/HelloWorld";
import TourShow from "../components/Tour/TourShow";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "signin",
        component: SignIn,
        meta: { requiresAuth: false },
    },
    {
        path: "/admin",
        component: Wrapper,
        children: [
            {
                path: "",
                name: "dashboard",
                component: Dashboard,
                meta: { requiresAuth: true },
            },
            {
                path: "/hello",
                name: "hello",
                component: HelloWorld,
                meta: { requiresAuth: true },
            },
            {
                path: "/categories",
                name: "categories",
                component: Categories,
                meta: { requiresAuth: true },
            },

            {
                path: "/tours",
                name: "tours",
                component: Tours,
                meta: { requiresAuth: true },
            },
            {
                path: "/tour/:id",
                name: "tour-show",
                component: TourShow,
                meta: { requiresAuth: true },
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",    
    routes,
});

// router.beforeEach((to, from, next) => {
//     if (to.name === "signin" && JSON.parse(localStorage.getItem("auth")))
//         next({ name: "dashboard" });
//     else if (to.name === "signin") next();
//     else if (
//         !JSON.parse(localStorage.getItem("auth")) &&
//         from.name !== "signin"
//     )
//         next({ name: "signin" });
//     else next();
// });

export default router;
