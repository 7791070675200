<template>
    <v-dialog v-model="dialog" persistent max-width="500px">
        <validation-observer ref="observer" v-slot="{ invalid, changed }">
            <v-form @submit.prevent="handleFormSubmit" ref="editFormRef">
                <v-card>
                    <v-card-title
                        class="font-weight-bold text-capitalize text-h5"
                        >{{ toggleFormTitle }}
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="4">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Day"
                                        :rules="{
                                            required: true                                            
                                        }"
                                        untouched: true
                                    >
                                        <v-text-field
                                            v-model="itinerary.day"
                                            :error-messages="errors"
                                            type="number"
                                            min="1"
                                            max="50"
                                            required
                                            label="Day"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="From"
                                        rules="required"
                                    >
                                        <v-text-field
                                            v-model="itinerary.from"
                                            :error-messages="errors"
                                            required
                                            label="From"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="To"
                                        rules="required"
                                    >
                                        <v-text-field
                                            v-model="itinerary.to"
                                            :error-messages="errors"
                                            required
                                            label="To"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                 <v-col cols="12" sm="12" md="12">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        :rules="{
                                                required: true,
                                                min: 10,
                                            }"
                                        name="short_desc"                                        
                                    >
                                        <v-textarea
                                            v-model="itinerary.short_desc"
                                            :error-messages="errors"                                            
                                            name="short_desc"
                                            label="Short Description"
                                        ></v-textarea>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        :rules="{
                                                required: true,
                                                min: 10,
                                            }"
                                        name="Description"                                        
                                    >
                                        <v-textarea
                                            v-model="itinerary.desc"
                                            :error-messages="errors"                                            
                                            name="desc"
                                            label="Description"
                                        ></v-textarea>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Time"
                                        :rules="{
                                            required: true                                           
                                        }"
                                    >
                                        <v-text-field
                                            v-model="itinerary.time"
                                            :error-messages="errors"
                                            type="number"
                                            min="1"
                                            max="50"
                                            required
                                            label="Time (h)"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Transport"
                                        rules="required"
                                    >
                                        <v-text-field
                                            v-model="itinerary.transport"
                                            :error-messages="errors"
                                            required
                                            label="Transport"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn depressed text @click="close"> Cancel </v-btn>
                        <v-btn type="submit" :disabled="invalid || !changed">
                            {{ toggleBtnTitle }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </validation-observer>
    </v-dialog>
</template>

<script>
import { required, digits, max, min } from "vee-validate/dist/rules";
import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
    ...required,
    message: "{_field_} can not be empty",
});
extend("max", {
    ...max,
    message: "{_field_} may not be greater than {length} characters",
});
extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits. ({_value_})",
});
extend("min", {
    ...min,
    message: "{_field_} may not be less than {length} characters",
});

export default {
    name: "itinerary-edit",
    props: ["dialog", "itinerary", "close", "handleFormSubmit", "isEdit"],
    components: {
        "validation-provider": ValidationProvider,
        "validation-observer": ValidationObserver,
    },      
    computed: {
        toggleBtnTitle() {
            return this.isEdit ? "Update" : "Create";
        },
        toggleFormTitle() {
            return this.isEdit ? "Update itinerary" : "Add new itinerary";
        },
        isFormTouchedOrInvalid() {
            return Object.keys(this.fields).some(key => this.fields[key].touched || this.fields[key].invalid);
        },
        
    },
};
</script>
