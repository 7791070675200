<template>
    <v-dialog :value="isOpen" :retain-focus="false" persistent max-width="500">
        <v-card>
            <v-card-title class="dark-50--text">
                Delete
                <span class="font-weight-bold text-uppercase mx-2">{{
                    modalData.name
                }}</span>
                {{ modalData.item }} {{ displayConstrained }} ?
            </v-card-title>

            <v-card-text
                >Are you sure to delete this {{ modalData.item }} ?</v-card-text
            >
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="close" color="grey darken-1" text>
                    Cancel
                </v-btn>
                <v-btn
                    @click="$emit('deleteConfirmed', modalData.id)"
                    color="primary"
                    text
                >
                    I'm sure
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: ["deleteConfirmed"],
    data() {
        return {
            isConstrained: false,
        };
    },
    computed: {
        ...mapGetters({
            isOpen: "modals/isDeleteModalOpen",
            modalData: "modals/deleteModalData",
        }),

        displayConstrained() {
            return this.modalData.constrained
                ? "with " + this.modalData.constrained.length + " trips"
                : "";
        },
    },
    mounted() {},

    methods: {
        ...mapActions({
            close: "modals/closeDialog",
        }),
    },
};
</script>
