<template>
    <div>
        <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="grey" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>

            <v-list dense>
                <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    @click="handleMenuClick(i, category)"
                >
                    <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <dialog-edit
            v-if="dialogs.edit"
            :category="category"
            @closeDialog="dialogs.edit = false"
        />
        <dialog-delete  
            @deleteConfirmed="handleDelete"                  
        />
    </div>
</template>

<script>
import DialogDeleteVue from "@/components/Dialogs/DialogDelete.vue";
import DialogEditVue from "@/components/Dialogs/DialogEdit.vue";
import { categoryDelete } from "@/services/categoryService";
import { bus } from "@/main";
import { mapActions } from 'vuex';

export default {
    props: ["category"],
    components: {
        "dialog-edit": DialogEditVue,
        "dialog-delete": DialogDeleteVue,
    },

    data: () => ({
        dialogs: {
            edit: false,
            delete: false,
        },
        items: [
            {
                title: "Edit",
                icon: "mdi-pencil",
                onClick() {
                    this.dialogs.edit = true;
                },
            },
            {
                title: "Delete",
                icon: "mdi-delete",
                onClick(data) {
                    this.openDialog(data);
                },
            },
        ],
    }),
    methods: {
        ...mapActions({
            openDialog: "modals/openDialog",
            closeDialog: "modals/closeDialog",
        }),
        handleMenuClick(i, data) {
            console.log(data);
            const category =  {id: data.id, name: data.name, item: 'Category', constrained: data.trips}
            this.items[i].onClick.call(this, category); // bind this to onClick scope
        },
        handleDelete(categoryId) {            
            categoryDelete(categoryId).then(() => {
                bus.$emit("rerender_categories");   
                this.closeDialog();
            });
        },        
    },
};
</script>
