import { api } from "./index";

export const tourAll = async () => {
    return api.get("tour/all");
};

export const tourAdd = async (tour) => {
    return api.post("tour/add", tour);
};

export const tourShow = async (tourId) => {
    return api.get(`/tour/${tourId}`);
};

export const tourEdit = async (tourId, data) => {
    return api.put(`/tour/update/${tourId}`, data);
};

export const tourDelete = async (tourId) => {
    return api.delete(`/tour/delete/${tourId}`);
};

export function tourFilter(filters) {
    return api.get(`/tour/find`, {params: filters});
}

export const tourImageUpload = async (tourId, image) => {
    return api.post(`/tour/${tourId}/image-upload`, image, {headers: {"Content-Type": "multipart/form-data"}});
};
