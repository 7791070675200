import axios from "axios";

export const api = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        "Content-type": "application/json",
    },
});

export const base = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        "Content-type": "application/json",
    },
});

// api.interceptors.response.use(
//     (res) => {
//         return res;
//     },
//     (err) => {
//         if (err.response.status === 419 || err.response.status === 401) {
//             localStorage.removeItem("auth");
//             window.location.href = "/";
//             console.log("SESSION EXPIRED ...");
//         }
//         return Promise.reject(err);
//     }
// );
