<template>
    <div>
        <v-row>
            <v-col xl="4">
                <v-card elevation="0">
                    <v-card-title
                        class="display-3 font-weight-bold text-capitalize py-5"
                        >{{ tour.name }}</v-card-title
                    >
                    <div
                        class="d-flex justify-space-start align-center font-weight-bold"
                    >
                        <v-card-subtitle
                            class="text-capitalize font-weight-bold text-h5 py-0"
                        >
                            {{ tour.duration }} Days
                        </v-card-subtitle>
                        <v-card-subtitle
                            class="text-capitalize font-weight-bold text-h5 py-0"
                        >
                            € {{ tour.price }}
                        </v-card-subtitle>
                    </div>
                    <v-card-subtitle
                        class="text-decoration-underline text-uppercase mt-10 font-weight-medium pb-2"
                    >
                        Tour description
                    </v-card-subtitle>
                    <v-card-text>{{ tour.desc }}</v-card-text>
                </v-card>
            </v-col>

            <v-col xl="4" offset-xl="2">
                <v-card elevation="0" max-width="400">
                    <v-card-title class="pl-0">DEFAULT IMAGE</v-card-title>

                    <v-img
                        :lazy-src="tour.img_url"
                        max-height="250"
                        :src="tour.img_url"
                    >
                    <div class="pt-2 pl-2">

                        <v-btn color="dark" icon>
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </div>
                    </v-img>

                    <v-card-actions class="mt-5">
                        <v-btn @click="uploadDialog = true" small class=""
                            >Upload new image</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <!-- TABS -->
        <v-row class="mt-15">
            <v-col lg="8">
                <v-tabs fixed-tabs light color="dark">
                    <v-tab> Itineraries </v-tab>
                    <v-tab> Accomodations </v-tab>
                    <v-tab> Inclusions </v-tab>
                    <v-tab> Not include </v-tab>

                    <v-tab-item>
                        <v-container fluid>
                            <v-row>
                                <v-col md="12" class="px-0">
                                    <div class="mt-5">
                                        <div class="mb-10">
                                            <v-btn
                                                @click="openAdd"
                                                class="mx-2"
                                                small
                                                color="dark"
                                                depressed
                                            >
                                                <v-icon>mdi-plus</v-icon>
                                                New Itinerary
                                            </v-btn>
                                        </div>
                                        <v-expansion-panels>
                                            <v-expansion-panel
                                                v-for="(item, i) in itineraries"
                                                :key="i"
                                            >
                                                <v-expansion-panel-header
                                                    class="text-capitalize font-weight-bold"
                                                >
                                                    <div>
                                                        <v-chip
                                                            small
                                                            class="me-4"
                                                            color="primary"
                                                        >
                                                            Day {{ item.day }}
                                                        </v-chip>
                                                        {{ item.short_desc }}
                                                    </div>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <div class="mb-5">
                                                        {{ item.desc }}
                                                    </div>
                                                    <v-divider />
                                                    <div
                                                        class="d-flex justify-space-between align-center mt-5 float-right"
                                                    >
                                                        <v-btn
                                                            class="mx-2"
                                                            fab
                                                            depressed
                                                            x-small
                                                            color="dark"
                                                            @click="
                                                                openEdit(item)
                                                            "
                                                        >
                                                            <v-icon
                                                                >mdi-pencil</v-icon
                                                            >
                                                        </v-btn>
                                                        <v-btn
                                                            @click="
                                                                openDeleteDialog(
                                                                    item
                                                                )
                                                            "
                                                            class="mx-2"
                                                            fab
                                                            x-small
                                                            color="dark"
                                                            depressed
                                                        >
                                                            <v-icon
                                                                >mdi-delete</v-icon
                                                            >
                                                        </v-btn>
                                                    </div>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs>
            </v-col>
        </v-row>

        <itinerary-edit
            :itinerary="itinerary"
            :dialog="dialog"
            :close="closeEditDialog"
            :handleFormSubmit="handleFormSubmit"
            :isEdit="isEdit"
            ref="editFormRef"
        ></itinerary-edit>

        <!-- IMAGE UPLOAD -->
        <v-dialog
            :value="uploadDialog"
            :retain-focus="false"
            persistent
            max-width="500"
        >
            <v-card elevation="0" class="">
                <v-card-title>Upload image</v-card-title>
                <v-form class="px-5 mt-5" ref="uploadFormRef">
                    <v-file-input
                        label="Choose image"
                        filled
                        accept="image/*"
                        prepend-icon="mdi-camera"
                        @change="selectImg"
                    ></v-file-input>
                </v-form>

                <div v-if="progress">
                    <div>
                        <v-progress-linear
                            v-model="progress"
                            color="light-blue"
                            height="25"
                            reactive
                        >
                            <strong>{{ progress }} %</strong>
                        </v-progress-linear>
                    </div>
                </div>

                <div v-if="previewImage" class=" d-flex align-center justify-center pa-4 mx-auto">
                    <v-img
                        :lazy-src="previewImage"
                        max-height="200"                       
                        :src="previewImage"
                    ></v-img>
                </div>
                <v-card-text v-if="uploadError" class="red--text">{{ uploadError }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        class="mx-2"
                        fab
                        depressed
                        small
                        color="dark"
                        @click="clearImagePreview"
                        :disabled="!previewImage"
                    >
                        <v-icon dark> mdi-image-remove </v-icon>
                    </v-btn>
                    <v-btn
                        class="mx-2"
                        fab
                        depressed
                        small
                        color="dark"
                        @click="closeUpoadDialog"
                    >
                        <v-icon dark> mdi-close </v-icon>
                    </v-btn>

                    <v-btn
                        class="mx-2"
                        fab
                        
                        small
                        color="dark"
                        @click.prevent="sendImg"
                         :disabled="!currentImage"
                    >
                        <v-icon dark> mdi-cloud-upload </v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- DIALOG DELETE-->
        <dialog-delete @deleteConfirmed="deleteItemConfirm" />
    </div>
</template>
<script>
import { tourShow, tourImageUpload } from "@/services/tourService";
import {
    itineraryEdit,
    itineraryAdd,
    itineraryDelete,
} from "@/services/itineraryService";
import ItineraryEdit from "@/components/Dialogs/ItineraryEdit.vue";
import DialogDelete from "@/components/Dialogs/DialogDelete.vue";
import { mapActions } from "vuex";

export default {
    components: { ItineraryEdit, DialogDelete },
    data() {
        return {
            uploadDialog: false,
            uploadError: '',
            dialog: false,
            isEdit: false,
            tour: {},
            itineraries: [],
            itinerary: {
                day: "",
                from: "",
                to: "",
                short_desc: "",
                desc: "",
                time: "",
                duration: "",
            },
            defaultItinerary: {
                day: "",
                from: "",
                to: "",
                short_desc: "",
                desc: "",
                time: "",
                duration: "",
            },
            editedIndex: -1,
            currentImage: undefined,
            previewImage: undefined,
            progress: 0,
        };
    },
    created() {},
    mounted() {
        this.getTour();
    },

    methods: {
        ...mapActions({
            openDialog: "modals/openDialog",
            closeDialog: "modals/closeDialog",
        }),
        async getTour() {
            const res = await tourShow(this.$route.params.id);
            const { tour, itineraries } = res.data;
            this.tour = tour;
            this.itineraries = itineraries;
        },
        openAdd() {
            this.isEdit = false;
            this.dialog = true;
            this.itinerary = this.defaultItinerary;
        },
        openEdit(itinerary) {
            this.editedIndex = this.itineraries.indexOf(itinerary);
            this.isEdit = true;
            this.itinerary = Object.assign({}, itinerary);
            this.dialog = true;
        },

        closeEditDialog() {
            this.$refs.editFormRef.$refs.editFormRef.reset();
            this.$refs.editFormRef.$refs.observer.reset();
            this.dialog = false;
        },

        handleItineraryAdd() {
            const newItem = Object.assign(
                {},
                { trip_id: this.$route.params.id },
                this.itinerary
            );
            itineraryAdd(newItem);
        },

        handleItineraryEdit() {
            itineraryEdit(this.itinerary.id, this.itinerary);
        },

        handleFormSubmit() {
            this.isEdit
                ? this.handleItineraryEdit()
                : this.handleItineraryAdd();
            this.getTour();
            this.closeEditDialog();
        },

        openDeleteDialog(data) {
            const trip = {
                id: data.id,
                name: "Day-" + data.day,
                item: "Itinerary",
            };
            this.openDialog(trip);
        },

        deleteItemConfirm(itineraryId) {
            itineraryDelete(itineraryId).then(() => {
                this.getTour();
                this.closeDialog();
            });
        },

        selectImg(image) {
            this.progress = 0;
            this.currentImage = image;
            this.currentImage
                ? (this.previewImage = URL.createObjectURL(this.currentImage))
                : (this.previewImage = undefined);
        },
        sendImg() {
            if (this.currentImage) {
                let formData = new FormData();
                formData.append("img", this.currentImage);
                tourImageUpload(this.$route.params.id, formData, (event) => {
                    this.progress = Math.round(
                        (100 * event.loaded) / event.total
                    );
                }).then((res) => {
                    if(res.status === 201){
                        console.log(res.data);
                        this.getTour()
                        this.closeUpoadDialog()
                    }
                }).catch(err => {
                    if(err.response.status === 422){
                        this.uploadError = err.response.data.message
                    }
                       
                })
            }
        },
        clearImagePreview() {
            this.previewImage = undefined;
            this.$refs.uploadFormRef.reset();
            this.uploadError = '';
        },

        closeUpoadDialog() {
            this.$refs.uploadFormRef.reset();
            this.uploadDialog = false;
            this.uploadError = '';
        },
    },
};
</script>
