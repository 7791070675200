import {api} from "./index";

export const userShow = async () => {
    return api.get('/user');
};

export const userEdit = async (userId) => {
    return api.get(`/user/edit/${userId}`);
};

export const userDelete = async (userId) => {
    return api.get(`/user/delete/${userId}`);
};
