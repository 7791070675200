<template>
    <div>
        <v-list-item :ripple="false" class="white">
            <template v-slot:default="">
                <v-list-item-content class="list-header-id">
                    <v-list-item-title>{{ category.id }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                    <v-list-item-title>{{ category.name }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                    <category-menu :category="category" />
                </v-list-item-action>
            </template>
        </v-list-item>

        <v-divider></v-divider>
    </div>
</template>

<script>
import CategoryMenu from "@/components/Category/CategoryMenu.vue";

export default {
    props: ["category"],

    components: {
        "category-menu": CategoryMenu,
    },
};
</script>
<style scoped>
.v-list-item__content.list-header-id {
    max-width: 10%;
}
</style>
