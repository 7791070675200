<template>
    <v-app>
        <v-navigation-drawer v-model="drawer" app :mobile-breakpoint="768">
            <v-list-item class="pt-5 pb-16">
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        Lemuria Discovery
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list dense nav>
                <v-list-item
                    v-for="item in items"
                    :key="item.title"
                    :to="item.to"
                    link
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app color="white" elevation="0" class="pr-5">
            <v-app-bar-nav-icon
                @click="drawer = !drawer"
                class=""
            ></v-app-bar-nav-icon>

            <v-spacer></v-spacer>

            <v-btn icon>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <v-btn icon>
                <v-icon>mdi-bell-outline</v-icon>
            </v-btn>

            <v-btn icon>
                <v-icon>mdi-email-outline</v-icon>
            </v-btn>

            <v-btn icon class="ml-5">
                <v-menu offset-y nudge-bottom="8" min-width="200">
                    <template v-slot:activator="{ on, attrs }">
                        <v-avatar size="50" v-bind="attrs" v-on="on">
                            <img
                                src="https://cdn.vuetifyjs.com/images/john.jpg"
                                alt="John"
                            />
                        </v-avatar>
                    </template>
                    <v-list dense nav>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    user ? user.name : ""
                                }}</v-list-item-title>
                                <v-list-item-subtitle>{{
                                    user ? user.email : ""
                                }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item-group color="dark" class="mt-5">
                            <v-list-item
                                v-for="(item, i) in items"
                                :key="i"
                                :to="item.to"
                                link
                            >
                                <v-list-item-icon>
                                    <v-icon v-text="item.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="item.title"
                                    ></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="logout">
                                <v-list-item-icon>
                                    <v-icon color="red">mdi-power</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        >Sign Out</v-list-item-title
                                    >
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </v-btn>
        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { signOut } from "../services/auth";
import { mapActions } from "vuex";

export default {
    name: "wrapper",
    data: () => ({
        drawer: null,
        items: [
            { title: "dashboard", icon: "mdi-view-dashboard", to: "/admin" },
            { title: "Hello", icon: "mdi-help-box", to: "/hello" },
            {
                title: "Categories",
                icon: "mdi-palette-swatch",
                to: "/categories",
            },
            { title: "Tours", icon: "mdi-nature-people", to: "/tours" },
        ],
    }),
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
            user: "auth/user",
        }),
    },
    methods: {
        ...mapActions({
        clearAuthFromStorage: "auth/removePersistedAuth",
        }),

        logout() {           
            signOut().then(() => {
                this.clearAuthFromStorage();
                this.$router.push({ path: "/" });
            });
        },
        toggleDrawer() {
            this.$emit("toggleDrawer");
        },
    },
};
</script>
<style lang="scss" scoped>
main.v-main {
    padding: 1rem !important;
}
.v-menu__content {
    box-shadow: 5px 5px 35px 5px #e6e6e6;
}
</style>
