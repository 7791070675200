import { userShow } from "@/services/userService";
import { initCookie, signIn } from "@/services/auth";

export default {
    namespaced: true,

    state: {
        authenticated: false,
        user: null,
    },

    getters: {
        authenticated(state) {
            return state.authenticated;
        },
        user(state) {
            return state.user;
        },
    },

    mutations: {
        SET_AUTHENTICATED(state, value) {
            state.authenticated = value;
        },
        SET_USER(state, value) {
            state.user = value;
        },
        INIT_AUTH(state) {
            if (localStorage.getItem("auth")) {
                state.user = JSON.parse(localStorage.getItem("auth"));
            }
            state.authenticated = true;
        },
    },

    actions: {
        async signIn({ commit, dispatch }, credentials) {
            await initCookie();
            await signIn(credentials);

            return dispatch("getUser");
        },

        getUser({ commit }) {
            userShow()
                .then((res) => {
                    const auth = { ...res.data, is_auth: true };
                    localStorage.setItem("auth", JSON.stringify(auth));
                    commit("SET_AUTHENTICATED", true);
                    commit("SET_USER", res.data);
                })
                .catch((err) => {
                    commit("SET_AUTHENTICATED", false);
                    commit("SET_USER", null);
                });
        },
        removePersistedAuth({ commit }) {
            if (localStorage.getItem("auth")) localStorage.removeItem("auth");
            commit("SET_AUTHENTICATED", false);
            commit("SET_USER", null);
        },
    },
};
