<template>
    <v-dialog :value="true" persistent max-width="450">
        <v-form @submit.prevent="addCategory">
            <v-card>
                <v-card-title
                    class="text-h5 light-blue--text font-weight-bold text-uppercase"
                >
                    Add a new Category
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="item.name"
                        color="light-blue"
                        placeholder="min 5 characters"
                        :rules="rules"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="$emit('closeDialog')" color="grey" text>
                        Cancel
                    </v-btn>
                    <v-btn
                        type="submit"
                        color="dark"
                        text
                        :disabled="handleDisabled"
                    >
                        Add category
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { categoryAdd } from "@/services/categoryService";
import { bus } from "@/main";

export default {
    name: "dialog-add",
    props: ["category"],
    data: () => ({
        item: {
            name: "",
        },
        rules: [
            (value) => !!value || "Required.",
            (value) => (value && value.length >= 5) || "Min 5 characters",
        ],
    }),
    computed: {
        handleDisabled() {
            return this.item.name.length > 4 ? false : true;
        },
    },
    methods: {
        addCategory() {
            categoryAdd(this.item).then((res) => {
                bus.$emit("rerender_categories");
                this.$emit("closeDialog");
            });
        },
    },
};
</script>
