<template>
    <v-app>
        <div class="sign-in">
            <div class="form-wrap">
                <h1 class="mb-5">Sign In</h1>
                <v-form ref="form" @submit.prevent="handleFormSubmit">
                    <v-text-field
                        type="email"
                        v-model="user.email"
                        label="Email"
                        outlined
                        append-icon="mdi-email"
                        :rules="rules.email"
                    ></v-text-field>

                    <v-text-field
                        v-model="user.password"
                        :append-icon="togglePwd ? 'mdi-eye' : 'mdi-eye-off'"
                        outlined
                        :type="togglePwd ? 'text' : 'password'"
                        name="password"
                        label="Password"
                        @click:append="togglePwd = !togglePwd"
                        :rules="rules.password"
                    ></v-text-field>

                    <v-checkbox
                        v-model="user.checkbox"
                        label="Remember me"
                    ></v-checkbox>

                    <v-btn
                        type="submit"
                        color="primary"
                        block
                        x-large
                        class="mt-3"
                        :disabled="handleDisableBtn"
                    >
                        Sign In
                    </v-btn>
                </v-form>
            </div>
        </div>
    </v-app>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            user: {
                email: "",
                password: "",
                checkbox: false,
            },
            togglePwd: false,
            rules: {
                email: [
                    (value) => !!value || "Required.",
                    (value) =>
                        (value && this.isEmailValid(value)) ||
                        "Not a valid email",
                ],
                password: [
                    (value) => !!value || "Required.",
                    (value) =>
                        (value && this.isPwdValid(value)) || "Min 5 characters",
                ],
            },
        };
    },
    computed: {
        handleDisableBtn() {
            return !this.isEmailValid(this.user.email) || !this.isPwdValid(this.user.password);
        },
    },
    methods: {
        ...mapActions({
            signIn: "auth/signIn",
        }),

        handleFormSubmit() {
            this.signIn(this.user).then(() =>
                this.$router.push({ name: "dashboard" })
            );
        },
        isEmailValid(value) {
            return value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) !== null;                
        },
        isPwdValid(value){
            return value.length >= 5;
        }
    },
};
</script>
<style lang="scss" scoped>
.sign-in {
    position: relative;
    height: 100vh;
    .form-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        height: 400px;
        padding: 1rem;
    }
}
</style>
