export default {
    namespaced: true,

    state: {
        dialogDelete: {
            isOpen: false,
            data: ''
        }
    },

    getters: {
        isDeleteModalOpen(state){
            return state.dialogDelete.isOpen
        },  
        deleteModalData(state){
            return state.dialogDelete.data
        },        
    },
    mutations: {
        OPEN_DIALOG(state, data) {
            state.dialogDelete.isOpen = true
            state.dialogDelete.data = data
        },
        CLOSE_DIALOG(state) {
            state.dialogDelete.isOpen = false
            state.dialogDelete.data = ''
        },
    },

    actions: {
        openDialog({commit}, data){
            commit('OPEN_DIALOG', data)
        },
        closeDialog({commit}){
            commit('CLOSE_DIALOG')
        },
    }
}