import {base} from './index';

export const signIn = async (credentials) => {
    return base.post('/login', credentials);
}

export const signOut = async () => {
    return base.post('/logout');
}

export const initCookie = async () => {
    return base.get('/sanctum/csrf-cookie');
}