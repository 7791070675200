var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var changed = ref.changed;
return [_c('v-form',{ref:"editFormRef",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-bold text-capitalize text-h5"},[_vm._v(_vm._s(_vm.toggleFormTitle)+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Day","rules":{
                                        required: true                                            
                                    },"untouched:":"","true":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"number","min":"1","max":"50","required":"","label":"Day"},model:{value:(_vm.itinerary.day),callback:function ($$v) {_vm.$set(_vm.itinerary, "day", $$v)},expression:"itinerary.day"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"From","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","label":"From"},model:{value:(_vm.itinerary.from),callback:function ($$v) {_vm.$set(_vm.itinerary, "from", $$v)},expression:"itinerary.from"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"To","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","label":"To"},model:{value:(_vm.itinerary.to),callback:function ($$v) {_vm.$set(_vm.itinerary, "to", $$v)},expression:"itinerary.to"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":{
                                            required: true,
                                            min: 10,
                                        },"name":"short_desc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"name":"short_desc","label":"Short Description"},model:{value:(_vm.itinerary.short_desc),callback:function ($$v) {_vm.$set(_vm.itinerary, "short_desc", $$v)},expression:"itinerary.short_desc"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":{
                                            required: true,
                                            min: 10,
                                        },"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"name":"desc","label":"Description"},model:{value:(_vm.itinerary.desc),callback:function ($$v) {_vm.$set(_vm.itinerary, "desc", $$v)},expression:"itinerary.desc"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Time","rules":{
                                        required: true                                           
                                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"number","min":"1","max":"50","required":"","label":"Time (h)"},model:{value:(_vm.itinerary.time),callback:function ($$v) {_vm.$set(_vm.itinerary, "time", $$v)},expression:"itinerary.time"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Transport","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","label":"Transport"},model:{value:(_vm.itinerary.transport),callback:function ($$v) {_vm.$set(_vm.itinerary, "transport", $$v)},expression:"itinerary.transport"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"type":"submit","disabled":invalid || !changed}},[_vm._v(" "+_vm._s(_vm.toggleBtnTitle)+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }