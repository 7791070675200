<template>
    <div>
        <v-row>
            <v-col lg="6">
                <div class="d-flex justify-space-between align-center">
                    <div class="text-h5 font-weight-bold">ALL CATEGORIES</div>
                    <v-btn outlined color="dark" @click="dialog.add = true">
                        New Category
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <v-row class="mt-15">
            <v-col lg="6">
                <div class="d-flex align-center px-5 header">
                    <div class="header__item">ID</div>
                    <div class="header__item">NAME</div>
                    <div class="header__item">ACTIONS</div>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col lg="6">
                <v-list flat>
                    <category-item
                        v-for="category in categories"
                        :key="category.id"
                        :category="category"
                    />
                </v-list>
            </v-col>
        </v-row>

        <dialog-add v-if="dialog.add" @closeDialog="dialog.add = false" />
    </div>
</template>

<script>
import { categoryAll } from "@/services/categoryService";
import CategoryItem from "@/components/Category/CategoryItem.vue";
import DialogAdd from "@/components/Dialogs/DialogAdd.vue";
import { bus } from "@/main";

export default {
    components: { CategoryItem, "dialog-add": DialogAdd },
    data() {
        return {
            categories: [],
            headers: [
                { text: "id", value: "id" },
                { text: "name", value: "name" },
                { text: "action", value: "action" },
            ],
            items: ["edit", "delete"],
            dialog: {
                add: false,
            },
        };
    },
    async mounted() {
        this.fetchAllCategories();
    },
    created() {
        bus.$on("rerender_categories", () => {
            this.fetchAllCategories();
        });
    },
    methods: {
        async fetchAllCategories() {
            const { data } = await categoryAll();
            this.categories = data.categories;
        },
    },
};
</script>
<style lang="scss" scoped>
.header {
    font-weight: 700;
    &__item:nth-child(1) {
        width: 10%;
    }
    &__item:nth-child(2) {
        width: 60%;
    }
    &__item:nth-child(3) {
        width: 30%;
        text-align: right;
    }
}
</style>
