<template>
    <h3>Nice to see you back {{ user ? user.name : '' }}</h3>
</template>


<script>
import {mapGetters} from 'vuex';

export default {
    data(){
        return{  
            categories: []         
        }
    },   
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
        })
    },
    methods: {       
    }
}
</script>
